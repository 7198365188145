<template>
	<div class="download">
		<div class="back" v-if="isMobile">
			<svg-icon icon-class="logo" style="width: 80px; height: 40px"></svg-icon>
			<div style="font-size: 16px" @click="$router.back()">返回</div>
		</div>
		<navComponents v-if="!isMobile" />
		<div class="bgc">
			<div class="main">
				<div class="form">
					<div class="form-left">
						<div class="form-title" v-if="!isMobile">
							<svg class="icon" aria-hidden="true">
								<use xlink:href="#icon-logo2"></use>
							</svg>
							<div>|</div>
							<div>StaRiver RealTime Data Platform实时数据融合服务平台</div>
						</div>
						<div class="form-header">
							<h2>StaRiver RDP技术白皮书</h2>
							<div v-if="isMobile">详细阐述StaRiver RDP的技术特性和优势</div>
							<div class="divided" v-if="isMobile"></div>
							<div class="divided" v-if="isMobile"></div>
							<div class="bg" v-if="!isMobile">
								<img src="~@/assets/images/book_cover.png" alt="" />
							</div>
						</div>
						<div class="book_catalogue" v-if="!isMobile">
							<img src="~@/assets/images/book_catalogue.png" alt="" />
						</div>
						<el-form :model="infom" :rules="rules" ref="infom">
							<h3 style="margin-bottom: 20px" v-if="!isMobile">
								填写下载信息
								<span style="font-size: 14px; font-weight: normal">（白皮书将通过系统自动推送至您填写的邮箱中）</span>
							</h3>
							<div style="margin-bottom: 10px" v-if="isMobile">
								<h3>填写下载信息</h3>
								<span style="font-size: 14px; font-weight: normal; color: #595e67; line-height: 28px">白皮书将通过系统自动推送至您填写的邮箱中</span>
							</div>

							<el-form-item label="" prop="email">
								<span class="red">*</span>
								<el-input v-model="infom.email" placeholder="邮箱："></el-input>
								<p class="tips">我们将把相关试用资料发送到您指定邮箱。</p>
							</el-form-item>
							<el-form-item label="" prop="name">
								<span class="red">*</span>
								<el-input v-model="infom.name" placeholder="姓名："></el-input>
							</el-form-item>
							<el-form-item label="" prop="company">
								<span class="red">*</span>
								<el-input v-model="infom.company" placeholder="公司："></el-input>
								<p class="tips">请务必填写工商注册全名：例 xxx有限公司。</p>
							</el-form-item>
							<el-form-item label="" prop="position">
								<span class="red">*</span>
								<el-input v-model="infom.position" placeholder="职位："></el-input>
							</el-form-item>
							<el-form-item label="" prop="tel">
								<span class="red">*</span>
								<el-input v-model="infom.tel" placeholder="手机："></el-input>
							</el-form-item>
							<div class="divided" v-if="!isMobile"></div>
							<el-form-item label="您是从哪了解到StaRiver RDP的？" prop="q1" class="q1 block_item">
								<el-select v-model="infom.q1" placeholder="请选择">
									<el-option label="搜索引擎" value="1;搜索引擎"></el-option>
									<el-option label="技术文章" value="2;技术文章"></el-option>
									<el-option label="微信朋友圈" value="3;微信朋友圈"></el-option>
									<el-option label="技术课程" value="4;技术课程"></el-option>
									<el-option label="相关大会" value="5;相关大会"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="您需要试用产品吗?" prop="q3" class="q3 block_item">
								<el-radio-group v-model="infom.q3">
									<el-radio label="需要"></el-radio>
									<el-radio label="不需要"></el-radio>
								</el-radio-group>
							</el-form-item>
							<div class="divided" v-if="!isMobile"></div>
							<!-- <p class="warn">* 以上所有信息将严格保密，我们的技术专家将尽快联系您，并提供产品报价。</p> -->
							<el-form-item class="btn">
								<el-button :loading="loading" @click="submit('infom')">确认下载</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
				<div class="right" v-if="!isMobile">
					<ul>
						<!-- <li>
							<img ref="img" src="@/assets/images/productIntroduction-play.png" alt="" srcset="" />
							<video ref="video" src="http://istariver.com/sourse/video/product-introduction.mp4" controls></video>
							<p>StaRiver RDP操作视频推荐</p>
						</li> -->
						<li>
							<img src="@/assets/images/download-right.png" alt="" />
						</li>
					</ul>
				</div>
			</div>
			<p class="comapny-footer" v-if="!isMobile">StaRiver</p>
		</div>
		<CopyrightComponents />
	</div>
</template>

<script>
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
import { saveApplyForm } from "@/api/index.js";
export default {
	components: {
		navComponents,
		CopyrightComponents,
	},
	props: {},
	data() {
		let validateEmail = (rule, value, callback) => {
			let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/;
			if (value === "") {
				callback(new Error("请输入邮箱地址"));
			} else {
				if (!reg.test(value)) {
					callback(new Error("请输入正确的邮箱地址"));
				}
				callback();
			}
		};
		return {
			screenWidth: "",
			screenHeight: "",
			infom: {
				name: "",
				company: "",
				position: "",
				tel: "",
				email: "",
				q1: "",
				q2: "",
				q3: "",
			},
			rules: {
				name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
				company: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
				position: [{ required: true, message: "请输入职位", trigger: "blur" }],
				tel: [
					{ required: true, message: "请输入手机号", trigger: "blur" },
					{ min: 11, max: 11, message: "手机号必须是11位数", trigger: "blur" },
				],
				email: [
					{ required: true, message: "请输入邮箱地址", trigger: "blur" },
					{ validator: validateEmail, trigger: "blur" },
				],
				q1: [{ required: true, message: "请选择", trigger: "change" }],
				q2: [{ required: true, message: "请输入试用场景", trigger: "blur" }],
				q3: [{ required: true, message: "请选择", trigger: "change" }],
			},
			loading: false,
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {
		submit(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.loading = true;
					let data = {
						formKey: "req_download_tech_wpaper",
						formType: 1,
						originalData: {},
					};
					// 192.168.50.216:8909
					data.originalData.input1714100290732 = this.infom.name;
					data.originalData.input1714100299491 = this.infom.company;
					data.originalData.input1714100312997 = this.infom.position;
					data.originalData.input1714100357044 = this.infom.tel;
					data.originalData.input1714100014638 = this.infom.email;
					data.originalData.select1714100387712 = +this.infom.q1.split(";")[0];
					data.originalData.select1714100387712label = this.infom.q1.split(";")[1];
					data.originalData.radio1714100462625 = this.infom.q3 === "是，自有项目使用" ? 1 : 2;
					data.originalData.radio1714100462625label = this.infom.q3;
					console.log(data);
					saveApplyForm(data).then(res => {
						this.loading = false;
						if (res.code === 200) {
							this.$message({
								message: "发送成功，请在邮箱中查看。",
								type: "success",
							});
						} else {
							this.$notify({
								title: "服务器繁忙",
								message: "请联系管理员！",
								type: "error",
								customClass: "notifywarning",
							});
						}
					});
				} else {
					this.$notify({
						title: "提交失败",
						message: "请完善申请信息！",
						type: "error",
						customClass: "notifyerror",
					});
					console.log("error submit!!");
					return false;
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
.back {
	margin: 10px 15px 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #297aff;
}
/deep/.big-box .into-body-box-title {
	position: relative;
	// background: #297aff;
	background: url("~@/assets/images/apply-header.png") no-repeat center center;
	background-size: 100% 100%;
	.w {
		.logo,
		.title-nav {
			margin-top: 0;
			height: auto;
		}
		.logo {
			margin-top: 20px;
		}
		.title-nav .el-menu-demo .el-menu-item {
			height: auto;
		}
		.title-nav .el-menu-demo {
			height: auto;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}
.download {
	background-color: #f8f9fb;
}

.main {
	width: 1300px;
	margin: 40px auto 15px;
	display: flex;
	justify-content: space-between;
	.form {
		width: 900px;
		overflow: hidden;
		display: flex;
		justify-content: space-between;
		// margin-right: 20px;
		background-color: #f8f9fb;
		position: relative;
		height: 772px;
		overflow-y: scroll;
		padding-bottom: 20px;

		.form-left {
			width: 830px;
			position: absolute;
			left: 5px;
			box-shadow: 0px 6px 5px 2px rgba(4, 0, 0, 0.1);
			background-color: #fff;
		}
		.form-title {
			background: #297aff;
			height: 70px;
			display: flex;
			align-items: center;
			color: #fff;
			.icon {
				width: 100px;
				margin-top: 12px;
				margin-left: 30px;
			}
			div:last-child {
				margin-left: 25px;
			}
		}
		.form-header {
			padding: 65px 30px;
			display: flex;
			justify-content: space-between;
			position: relative;
			h2 {
				position: relative;
				&::after {
					content: "";
					display: inline-block;
					width: 60px;
					height: 4px;
					background: #297aff;
					position: absolute;
					left: 0;
					bottom: -30px;
				}
			}
			.bg {
				position: absolute;
				right: 34px;
				top: -45px;

				background-size: 90%;
			}
		}
		.book_catalogue {
			padding: 20px 30px;
			img {
				width: 100%;
				height: 100%;
			}
		}
		/deep/.el-form {
			padding: 0 30px;
			p {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #000000;
				line-height: 48px;
			}
			.red {
				color: red;
				position: absolute;
				z-index: 5;
				left: 5px;
			}
			.el-form-item__content {
				position: relative;
			}
			.el-form-item:nth-child(2),
			.el-form-item:nth-child(3),
			.el-form-item:nth-child(4),
			.el-form-item:nth-child(5),
			.el-form-item:nth-child(6) {
				display: flex;
				justify-content: space-between;
				.el-form-item__label {
					color: #000;
					padding: 0;
				}
				.el-form-item__content {
					flex: 1;

					.tips {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #6c6c6c;
						line-height: 16px;
						margin-top: 10px;
					}
				}
			}
			.block_item {
				display: flex;
				flex-direction: column;
				.el-form-item__label {
					text-align: left;
				}
			}
			.q1 {
				.el-select {
					width: 100%;
				}
			}
			.q3 {
				.el-radio-group {
					.el-radio {
						margin-right: 200px;
					}
				}
			}
			.divided {
				height: 1px;
				background-image: linear-gradient(to right, #bfbfbf 35%, rgba(255, 255, 255, 0) 0%); /* 35%设置虚线点x轴上的长度 */
				background-position: bottom; /* top配置上边框位置的虚线 */
				background-size: 10px 1px; /* 第一个参数设置虚线点的间距；第二个参数设置虚线点y轴上的长度 */
				background-repeat: repeat-x;
				margin: 20px 0;
			}
			.warn {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ff3232;
				line-height: 48px;
				text-align: center;
			}
			.btn {
				margin: 20px 0;
				.el-form-item__content {
					text-align: center;
				}
				.el-button {
					border: 0;
					padding: 0;
					width: 120px;
					height: 36px;
					background: #54cb9a;
					color: #fff;
					border-radius: 18px;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					line-height: 30px;
				}
			}
		}
	}
	.right {
		ul {
			li {
				list-style: none;
				width: 370px;
				height: 210px;
				margin-bottom: 40px;
				cursor: pointer;
				img,
				video {
					width: 370px;
					height: 210px;
				}
				video {
					display: none;
				}
				p {
					text-align: center;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #000000;
					a {
						text-decoration: none;
						color: #000000;
						display: none;
						&:hover {
							color: #297aff;
						}
					}
				}
			}
		}
	}
}
.comapny-footer {
	width: 1300px;
	margin: 0 auto 30px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	font-weight: 400;
	color: #383838;
	background-color: #f8f9fb;
}
@media (max-width: 900px) {
	.download {
		background-color: #fff;
	}
	.main {
		width: 92%;
		margin-top: 10px;
		.form {
			width: 100%;
			overflow-x: auto;
			overflow-y: auto;
			height: auto;
			padding-bottom: 0;
			background-color: #fff;
			.form-left {
				width: 100%;
				box-shadow: unset;
				background-color: inherit;
				overflow-x: auto;
				position: relative;
				left: 0;
				.form-header {
					padding: 0;
					flex-direction: column;
					h2::after {
						display: none;
					}
					div {
						font-size: 14px;
						color: #595e67;
						line-height: 30px;
					}
					.divided {
						border-bottom: 1px solid #e4e4e4;
						margin-top: 10px;
						&:last-child {
							border-bottom-width: 2px;
							margin-top: 3px;
							margin-bottom: 15px;
						}
					}
				}
				.el-form {
					padding: 0;
					p {
						font-size: 12px;
						color: #595e67;
						line-height: 20px;
						margin: 15px 0;
					}
					.warn {
						color: #ff3232;
					}
					.q3 {
						/deep/.el-form-item__content {
							margin-top: 10px;
						}
						.el-radio-group {
							display: flex;
							flex-direction: column;
							.el-radio {
								margin-right: 0;
								margin-bottom: 10px;
							}
						}
					}
					.q1,
					.q3 {
						background: #f3f5fa;
						margin-bottom: 0;
						padding: 0 10px 18px;
						/deep/.el-form-item__label {
							color: #000;
						}
					}
					.btn {
						margin-bottom: 0;
						.el-button {
							width: 100%;
							border-radius: 5px;
							color: #fff;
						}
					}
					.el-form-item:nth-child(2),
					.el-form-item:nth-child(3),
					.el-form-item:nth-child(4),
					.el-form-item:nth-child(5),
					.el-form-item:nth-child(6) {
						display: flex;
						justify-content: space-between;
						.el-form-item__label {
							color: #000;
							padding: 0;
						}
						.el-form-item__content {
							flex: 1;

							.tips {
								color: #a0a0a0;
								margin-bottom: 0px;
							}
						}
					}
				}
			}
		}
	}
}
</style>
